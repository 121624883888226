// 董事长介绍
<template>
  <div class="company-chairmanProfile">
    <div class="chairmanProfile-info">
      <Info class="info-text" />
    </div>
    <div class="chairmanProfile-achievement">
      <h3 class="achievement-title align-center">
        <img :src="require('@/assets/images/gsgk-img2.png')" />
        社会成就
      </h3>
      <ul class="achievement-list">
        <li class="achievement-item align-center" v-for="item in achievement" :key="item.id">
          <img :src="require('@/assets/images/gsgk-img3.png')" alt="">
          <p class="achievement-name align-center">
            <span>{{item.name}}</span>
            <i></i>
          </p>
          <span class="achievement-position">{{item.position}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getSocal } from '@/api'
import Info from '@/components/Info'
export default {
  name: 'ChairmanProfile',
  components: {
    Info
  },
  data () {
    return {
      achievement: [],
    };
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        let { data } = await getSocal()
        this.achievement = data
      } catch (error) {

      }
    }
  },
};
</script>

<style scoped lang="less">
.chairmanProfile-info {
  height: 424px;
  background: url("../../assets/images/gsgk-bn1.png") no-repeat center;
  background-size: 100% auto;
  position: relative;
  .info-text {
    width: 63%;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    /deep/ p {
      line-height: 28px;
      &:first-child {
        margin-bottom: 54px;
        line-height: 0;
        font-size: 20px;
        color: #3e3e3e;
      }
      &:last-child {
        span {
          font-size: 16px !important;
          letter-spacing: 1px;
        }
      }
    }
  }
}
.chairmanProfile-achievement {
  .achievement-title {
    font-size: 20px;
    color: #313131;
    font-weight: bold;
    margin: 78px 0 24px;

    img {
      margin-right: 12px;
    }
  }
  .achievement-list {
    .achievement-item {
      padding: 15px 0;
      .achievement-name {
        margin-left: 12px;
        width: 60%;
        i {
          flex: 1;
          border-bottom: 3px dotted #919191;
          margin: 0 20px;
        }
      }
      .achievement-position {
        font-weight: bold;
      }
    }
  }
}
</style>
